import { DetailsListLayoutMode, SearchBox, Dropdown, IDropdownOption, FontSizes, getTheme, DetailsList, IColumn, SelectionMode, Icon, IconButton, Spinner, SpinnerSize, Stack, useTheme, FontIcon } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { IDocument } from '../../models/IDocument';
import { IProject } from '../../models/IProject';
import { StringService } from '../../Services/StringService'

import './documentsList.scss';
import { IMsalContext, useMsal } from '@azure/msal-react'
import { ApiService } from '../../Services/ApiService'

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setDocumentId, setDocumentName, setProjectId } from '../../Services/Global';
import { DocumentStatus, ProjectStatus } from '../../models/Enums';
import { Actions, hasPermission } from '../../Services/Permissions';

export const DocumentsList: React.FC<{}> = () => {

    const ctx: IMsalContext = useMsal()
    const apiService = new ApiService(ctx)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [data, setData] = useState<IDocument[]>([])
    const [filteredData, setFilteredData] = useState<IDocument[]>([])
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [projectData, setProjectData] = useState<IProject>();
    const [statusOptions, setStatusOptions] = useState<IDropdownOption[]>([]);
    const [projectStatus, setProjectStatus] = useState<ProjectStatus>();
    const [columns, setColumns] = useState<IColumn[]>();

    const theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();
    if (projectData === undefined) {
        setProjectData(location.state as IProject);
        setProjectStatus((location.state as IProject)?.status)
    }

    const params = useParams();
    const projectId = params.id === undefined ? '' : params.id;
    if (projectId) {
        setProjectId(projectId);
    }

    useEffect(() => {
        setColumns(hasPermission(Actions.DocumentDelete) ? columnSpec : columnSpec.slice(0, 4))
        const options: IDropdownOption[] = [];
        addOption('Active');
        addOption('Waiting');
        addOption('Completed');
        addOption('Lost');
        function addOption(s: string) {
            options.push({ key: s, text: StringService.getDocumentCategory(s) });
        }

        setStatusOptions(options);
        if (projectData === undefined || projectData === null) {
            loadProject()
        }
    }, [])

    useEffect(() => {
        if (projectData === undefined || projectData === null) { return }

        loadDocuments();
    }, [projectData?.id])

    useEffect(() => {
        setTimeout(
            () => {
                if (data.some(p => p.status === 'Analyzing')) {
                    loadDocuments(false);
                }
            }, 5000)
    }, [data])

    async function loadProject() {
        const response = await apiService.get(`projects/${projectId}`);
        const result: IProject = await response.json();
        setProjectData(result);
        setProjectStatus(result.status ?? undefined)
    }

    const columnSpec: IColumn[] = [
        {
            key: '1',
            name: 'Name',
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: false,
            isSorted: true,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '2',
            name: 'Description',
            fieldName: 'description',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '3',
            name: 'Revision',
            fieldName: 'revision',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '4',
            name: 'Category',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IDocument) => {
                return projectData?.isTraining ? "" : StringService.getDocumentCategory(item.category)
            }
        },
        {
            key: '5',
            name: 'Status',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IDocument) => {
                return renderDocumentStatus(StringService.getDocumentStatus(item.status))
            }
        },
        {
            key: '6',
            name: 'Training',
            minWidth: 30,
            maxWidth: 30,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IDocument) => {
                return item.isTraining ? "✓" : ""
            }
        },
        {
            key: '7',
            name: '',
            isRowHeader: true,
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            isPadded: true,
            onRender: renderDeleteButton
        },
    ]

    const getDocumentStatusColor = (status: string) => {
        let color: string = ''
        switch (status as DocumentStatus) {
            case 'Unopened':
                color = theme.palette.red;
                break;
            case 'Opened':
                color = theme.palette.blueMid;
                break;
            case 'Doing':
                color = theme.palette.yellowLight;
                break;
            case 'Done':
                color = theme.palette.green;
                break;
            case 'Failed':
                color = theme.palette.black;
                break;
            default:
                color = theme.palette.black;
                break;
        }

        return color;
    }

    const getDocumentStatusIcon = (status: string) => {
        let icon: string = ''
        switch (status as DocumentStatus) {
            case 'Unopened':
                icon = 'Hide3';
                break;
            case 'Opened':
                icon = 'View';
                break;
            case 'Doing':
                icon = 'Clock'
                break;
            case 'Done':
                icon = 'CheckMark';
                break;
            case 'Failed':
                icon = 'Error';
                break;
            default:
                icon = '';
                break;
        }

        return icon;
    }

    const renderDocumentStatus = (item: string) => {
        const loading: boolean = item.toLowerCase() === 'analyzing';
        let color: string = getDocumentStatusColor(item);
        let icon: string = getDocumentStatusIcon(item);

        return (
            loading ?
                <Stack horizontal>
                    <Stack.Item>
                        <p>{item}</p>
                    </Stack.Item>
                    <Stack.Item>
                        <Spinner size={SpinnerSize.small} style={{ marginLeft: 10, marginTop: 2 }} />
                    </Stack.Item>
                </Stack>
                :
                <div style={{ borderRadius: 3, padding: 2 }}>
                    <span>
                        <FontIcon iconName={icon} style={{ float: 'left', marginTop: 2, marginLeft: 2, marginRight: 5, color: color }} />
                        <p style={{ display: 'block', paddingLeft: 10 }}>{item}</p>
                    </span>

                </div>
        )
    }

    function renderDeleteButton(item: any, index?: number, column?: IColumn) {
        return <IconButton onClick={() => deleteDocument(item)} onFocus={(ev) => ev.stopPropagation()}>
            <Icon iconName='Delete' />
        </IconButton>
    }

    async function deleteDocument(e: IDocument) {
        if (window.confirm('Are you sure you want to delete the document?')) {
            var projectId = e.projectId ?? projectData!.id
            const ret = await apiService.delete(`projects/${projectId}/documents/${e.id}`);
            if (!ret.ok) {
                alert('Failed to delete document')
            }
            await loadDocuments()
        }
    }

    async function loadDocuments(showProgress: boolean = true) {
        if (showProgress) {
            setIsLoading(true)
        }
        async function getDocumentData() {
            try {
                const response = await apiService.get(`projects/${projectData?.id}/documents`)
                const data = await response.json();

                setData(data)
                setFilteredData(data)

            } finally {
                setIsLoading(false)
            }
        }

        getDocumentData().catch((error) => {
            setIsLoading(false)
            setErrorMessage(error.toString())
        })
    }


    const handleSelection = (e: IDocument) => {
        if (e !== undefined && e.status !== 'Analyzing') {
            setDocumentId(e.id);
            setDocumentName(e.name ?? "");
            navigate(`/projects/${projectData?.id}/documents/${e.id}`, { state: { projectId: projectData?.id, documentId: e.id } });
        }
    }

    const onStatusChange = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<ProjectStatus>, index?: number | undefined) => {
        const value = option!.key as string;
        await apiService.put(`projects/${projectId}/status/${value}`)
        setProjectStatus(value as ProjectStatus)
    }

    const handleSearch = async (e: any) => {
        if (e === undefined) {
            e = ""
        }
        const newData = data.filter(z => z.name?.toLowerCase().includes(e.toLowerCase()))
        setFilteredData(newData)
    }

    return (
        <div>
            <Stack style={{ paddingRight: 10 }} horizontal horizontalAlign='end'>
                {
                    isLoading !== true && projectData !== undefined && !projectData.isTraining ?
                        <>
                            <div style={{ marginTop: 5, marginRight: 10 }}>
                                <Stack horizontal>
                                    <Stack.Item>
                                        <span style={{ display: 'block', color: 'white', backgroundColor: '#11387f', boxShadow: theme.effects.elevation8, height: 32, padding: 5, fontSize: FontSizes.size14 }}>Project Status:</span>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Dropdown style={{ marginLeft: '5px', width: 100 }}
                                            selectedKey={projectStatus}
                                            options={statusOptions}
                                            onChange={onStatusChange}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </div>
                        </>
                        : <></>
                }
                <Stack.Item>

                </Stack.Item>
            </Stack>
            {
                data !== undefined ?
                    <div style={{ justifyItems: 'center' }}>
                        {
                            isLoading === true ?
                                <Spinner label='Loading documents...' />
                                :

                                data.length !== 0 ?
                                    <Stack >
                                        <Stack.Item align='center' style={{ width: '500px' }}>
                                            <SearchBox placeholder='Search' onSearch={(e) => { handleSearch(e) }} onClear={(e) => handleSearch('')} />
                                        </Stack.Item>
                                        <Stack.Item align='center'>
                                            <div style={{ marginTop: '10px', fontWeight: 'bold' }}>Document count: {filteredData.length}</div>
                                        </Stack.Item>
                                        <Stack.Item align='center'>
                                            <DetailsList
                                                items={filteredData}
                                                columns={columns}
                                                onActiveItemChanged={handleSelection}
                                                selectionMode={SelectionMode.none}
                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                className='details-list-800 details-list-center'
                                                onShouldVirtualize={() => false}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    :
                                    <>No documents found.</>
                        }
                    </div>
                    :
                    <></>
            }

        </div>
    )
}